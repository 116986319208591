/* Shared styles for all nodes */
.node-template {
  padding: 16px;
  border-radius: 16px;
  min-width: 200px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  font-family: Arial, sans-serif;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

/* Light mode styles */
.node-template.light {
  background-color: #f5f5f5;
  color: #000;
  border: 1px solid #ccc;
}

.node-template.light .node-header {
  border-bottom: 1px solid #ddd;
  color: #0077cc;
}

.node-template.light .edit-icon {
  color: #666;
}

/* Dark mode styles */
.node-template.dark {
  background-color: #222;
  color: #fff;
  border: 1px solid #3a3a3a;
}

.node-template.dark .node-header {
  border-bottom: 1px solid #444;
  color: #00aaff;
}

.node-template.dark .edit-icon {
  color: #aaa;
}
