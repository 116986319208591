.image-list-item {
  position: relative;
  overflow: hidden;
}

.image-list-item img {
  display: block;
  width: 100%;
  height: 100%;
  transition: filter 0.3s ease-in-out;
}

.image-list-item:hover img {
  filter: brightness(50%);
}

.image-list-item-bar {
  position: relative;
}

.image-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px; /* Add spacing below buttons */
}

.responsive-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; /* Maintains aspect ratio and scales within the parent */
  display: block;
}

